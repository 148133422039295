







































































































import { Getter, Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import pdf from "vue-pdf";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
    Tag: () => import("@/components/general/tag.vue"),
    Comments: () => import("@/components/general/comments.vue"),
    DropdownOptions: () => import("@/components/general/dropdownOptions.vue"),
    ModalHunchAdd: () => import("@/components/hunches/modals/add.vue"),
    SourceUrl: () => import("@/components/general/source-url.vue"),
    LinkPreview: () => import("@/components/general/link-preview.vue"),
    DynamicVideo: () => import("@/components/general/video.vue"),
    pdf,
  },
})
export default class PageDotDetail extends Vue {
  @Getter("dot/viewing") dot!: Dot;

  @Getter("workspace/viewing") workspace!: Workspace;

  @Action("dot/delete") deleteDot!: (payload: { id: number }) => Promise<void>;

  tab = "comments";

  showAddHunch = false;

  pdf: { page: number; currentPage: number; pageCount: number } = {
    page: 1,
    currentPage: 0,
    pageCount: 0,
  };

  private handelDeleteDot() {
    this.deleteDot({ id: this.dot.id }).then(() => {
      this.$router.push({ name: "dots" });
    });
  }

  private get dotType(): "outline" | "pdf" | "link" | "video" | "image" {
    if (this.dot.outline && this.dot.outline.author) {
      return "outline";
    }

    if (this.dot.outline && (this.dot.outline.site_name === "vimeo" || this.dot.outline.site_name === "youtube")) {
      return "video";
    }

    if (this.dot.outline) {
      return "link";
    }

    if (this.dot.media && this.dot.media.type === "pdf") {
      return "pdf";
    }

    if (this.dot.media && this.dot.media.type !== "pdf") {
      return "image";
    }

    return "link";
  }
}
